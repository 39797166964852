import { ACTION_POPUP } from './actions'
import { IOverViewPopupRequest, IPopup, IPopupRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import popupApi from '@/api/popup'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IPopup[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    popups: (state: TState) => {
      return state.items as IPopup[]
    },
  },
  mutations: {
    // [List]
    [ACTION_POPUP.SET_ITEMS]: (state: TState, { items }: { items: IPopup[] }) => {
      state.items = cloneDeep(items) as IPopup[]
    },

    // [New item]
    [ACTION_POPUP.SET_NEW_ITEM]: (state: TState, { item }: { item: IPopup }) => {
      state.items = apiListChangedHandler<IPopup>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_POPUP.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IPopup }) => {
      state.items = apiListChangedHandler<IPopup>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_POPUP.SET_DELETED_ITEM]: (state: TState, { item }: { item: IPopup }) => {
      state.items = apiListChangedHandler<IPopup>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_POPUP.LOAD_ITEMS]: async ({ commit }, params: IPopupRequest) => {
      const key = constant.LOCAL_KEYS.POPUPS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await popupApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_POPUP.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_POPUP.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await popupApi.single(id)
      return resp
    },
    [ACTION_POPUP.ADD]: async ({ commit }, payload: IPopup) => {
      const resp = await popupApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS)
        commit(ACTION_POPUP.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_POPUP.UPDATE]: async ({ commit }, payload: IPopup) => {
      const resp = await popupApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS)
        commit(ACTION_POPUP.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_POPUP.DELETE]: async ({ commit }, id: string) => {
      const resp = await popupApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS)
        commit(ACTION_POPUP.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_POPUP.IS_ACTIVE]: async ({ commit }, payload: IPopup) => {
      const resp = await popupApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS)
        commit(ACTION_POPUP.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [ACTION_POPUP.TEST_MODE]: async ({ commit }, payload: IPopup) => {
      const resp = await popupApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.POPUPS)
        commit(ACTION_POPUP.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [ACTION_POPUP.OVERVIEW_DETAIL]: async ({ commit }, payload: IOverViewPopupRequest) => {
      const resp = await popupApi.popupOverviewDetail(payload)
      return resp
    },
  },
}
