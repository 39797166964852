import { ACTION_GOAL } from './actions'
import { IGoal } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import GoalApi from '@/api/goal'
import { apiListChangedHandler } from '@/utils/helpers'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type GoalState = {
  goals: IGoal[]
  loading: boolean
}

export default {
  state: {
    goals: [],
    loading: false,
  },
  getters: {
    goals: (state: GoalState) => {
      return state.goals as IGoal[]
    },
  },
  mutations: {
    // [List]
    [ACTION_GOAL.SET_ITEMS]: (state: GoalState, { goals }: { goals: IGoal[] }) => {
      state.goals = cloneDeep(goals)
    },

    // [New item]
    [ACTION_GOAL.SET_NEW_GOAL]: (state: GoalState, { item }: { item: IGoal }) => {
      state.goals = apiListChangedHandler<IGoal>(state.goals, cloneDeep(item), 'added')
    },

    // [Update item]
    [ACTION_GOAL.SET_UPDATED_GOAL]: (state: GoalState, { item }: { item: IGoal }) => {
      state.goals = apiListChangedHandler<IGoal>(state.goals, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_GOAL.SET_DELETED_GOAL]: (state: GoalState, { item }: { item: IGoal }) => {
      state.goals = apiListChangedHandler<IGoal>(state.goals, cloneDeep(item), 'removed')
    },
  },
  actions: {
    [ACTION_GOAL.LOAD_ITEMS]: async ({ commit }, payload: IGoal) => {
      const key = constant.LOCAL_KEYS.ASSETS_GOALS + payload.app_id + JSON.stringify(payload)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await GoalApi.list(payload)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_GOAL.SET_ITEMS, { goals: resp })
      return resp
    },

    [ACTION_GOAL.ADD]: async ({ commit }, payload: IGoal) => {
      const resp = await GoalApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_GOALS + resp.app_id)
        commit(ACTION_GOAL.SET_NEW_GOAL, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_GOAL.UPDATE]: async ({ commit }, payload: IGoal) => {
      const resp = await GoalApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_GOALS + resp.app_id)
        commit(ACTION_GOAL.SET_UPDATED_GOAL, { item: resp })
        return resp
      }

      return null
    },
    [ACTION_GOAL.DELETE]: async ({ commit }, id: string) => {
      const resp = await GoalApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_GOALS + resp.app_id)
        commit(ACTION_GOAL.SET_DELETED_GOAL, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_GOAL.CLONE]: async ({ commit }, id: string) => {
      const resp = await GoalApi.clone(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_GOALS + resp.app_id)
        commit(ACTION_GOAL.SET_NEW_GOAL, { item: resp })
        return resp
      }
      return null
    },
  },
}
