import { ICampaign, ICampaignRequest, IOverViewPopupRequest } from '@/utils/types'
import { NormApi } from './norm'

export class CampaignApi extends NormApi<ICampaign> {
  list = async (params: ICampaignRequest) => {
    const response = await this.axios.get('/v1/campaign/list', {
      params,
    })
    return this.responseHandler(response)
  }

  list_compact = async (params: ICampaignRequest) => {
    const response = await this.axios.get('/v1/campaign/list/compact', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/campaign/${id}`)
    return this.responseHandler(response)
  }

  story_single = async (id: string) => {
    const response = await this.axios.get(`/v1/campaign/story/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: ICampaign) => {
    const response = await this.axios.post('/v1/campaign/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: ICampaign) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/campaign/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/campaign/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: ICampaign) => {
    const response = await this.axios.post(`/v1/campaign/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: ICampaign) => {
    const response = await this.axios.post(`/v1/campaign/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }

  getCampaignTagJs = async () => {
    const response = await this.axios.get('/v1/campaign/tagurl')
    return this.responseHandler(response)
  }

  campaignOverviewDetail = async (payload: IOverViewPopupRequest) => {
    const response = await this.axios.post('/v1/campaign/overview', payload)
    return this.responseHandler(response)
  }
}

const campaignApi = new CampaignApi()
export default campaignApi
