import { IMessage } from '@/utils/types'
import { NormApi } from './norm'

export class MessageApi extends NormApi<IMessage> {
  list = async (params: IMessage) => {
    const response = await this.axios.get('/v1/messages/list', { params })
    return this.responseHandler(response)
  }

  single = async (params: IMessage) => {
    const response = await this.axios.get('/v1/messages', { params })
    return this.responseHandler(response)
  }

  add = async (payload: IMessage) => {
    const response = await this.axios.post('/v1/messages', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IMessage) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/messages/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/messages/${id}`)
    return this.responseHandler(response)
  }

  clone = async (id: string) => {
    const response = await this.axios.post(`/v1/messages/clone/${id}`)
    return this.responseHandler(response)
  }
}

const messagesApi = new MessageApi()
export default messagesApi
