import { IQuestion, IQuestionListRequest } from '@/utils/types'
import { ACTION_QUESTION } from './actions'
import { apiListChangedHandler } from '@/utils/helpers'

import cloneDeep from 'lodash/cloneDeep'
import questionApi from '@/api/question'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IQuestion[]
  question: IQuestion
  loading: boolean
}

export default {
  state: {
    items: [],
    question: [],
    loading: false,
  },
  getters: {
    questions: (state: TState) => {
      return state.items as IQuestion[]
    },
  },
  mutations: {
    // [List]
    [ACTION_QUESTION.SET_ITEMS]: (state: TState, { items }: { items: IQuestion[] }) => {
      state.items = cloneDeep(items)
    },
    // [New item]
    [ACTION_QUESTION.SET_NEW_ITEM]: (state: TState, { item }: { item: IQuestion }) => {
      state.items = apiListChangedHandler<IQuestion>(state.items, cloneDeep(item), 'added')
    },
    // [Update item]
    [ACTION_QUESTION.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IQuestion }) => {
      state.items = apiListChangedHandler<IQuestion>(state.items, cloneDeep(item), 'modified')
    },
    // [Deleted item]
    [ACTION_QUESTION.SET_DELETED_ITEM]: (state: TState, { item }: { item: IQuestion }) => {
      state.items = apiListChangedHandler<IQuestion>(state.items, cloneDeep(item), 'removed')
    },

    // [Answer]
    [ACTION_QUESTION.SET_ITEM]: (state: TState, { item }: { item: IQuestion }) => {
      state.question = cloneDeep(item)
    },
  },
  actions: {
    [ACTION_QUESTION.LOAD_ITEMS]: async ({ commit }, params: IQuestionListRequest) => {
      const key = constant.LOCAL_KEYS.ASSETS_QAS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await questionApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_QUESTION.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_QUESTION.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await questionApi.single(id)
    },
    [ACTION_QUESTION.ADD_NEW]: async ({ commit }, payload: IQuestion) => {
      const resp = await questionApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + resp.app_id)
        commit(ACTION_QUESTION.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_QUESTION.UPDATE]: async ({ commit }, payload: IQuestion) => {
      const resp = await questionApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + resp.app_id)
        commit(ACTION_QUESTION.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_QUESTION.DELETE]: async ({ commit }, id: string) => {
      const resp = await questionApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + resp.app_id)
        commit(ACTION_QUESTION.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_QUESTION.CLONE]: async ({ commit }, id: string) => {
      const resp = await questionApi.clone(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_QAS + resp.app_id)
        commit(ACTION_QUESTION.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
  },
}
