import { render } from "./AppSidebarLeft.vue?vue&type=template&id=8e7ebe88&scoped=true"
import script from "./AppSidebarLeft.vue?vue&type=script&lang=ts"
export * from "./AppSidebarLeft.vue?vue&type=script&lang=ts"

import "./AppSidebarLeft.vue?vue&type=style&index=0&id=8e7ebe88&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8e7ebe88"

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QScrollArea,QList,QItem,QItemSection,QIcon,QExpansionItem,QSeparator});qInstall(script, 'directives', {Ripple});
