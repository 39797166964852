import { IGoal } from '@/utils/types'
import { NormApi } from './norm'

export class GoalApi extends NormApi<IGoal> {
  list = async (params: IGoal) => {
    const response = await this.axios.get('/v1/goal/list', { params })
    return this.responseHandler(response)
  }

  add = async (payload: IGoal) => {
    const response = await this.axios.post('/v1/goal', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IGoal) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/goal/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/goal/${id}`)
    return this.responseHandler(response)
  }

  clone = async (id: string) => {
    const response = await this.axios.post(`/v1/goal/clone/${id}`)
    return this.responseHandler(response)
  }
}
const goalApi = new GoalApi()
export default goalApi
