// App
export const SET_PAGE_NOT_FOUND = 'app/setPage404'
export const RESET_PAGE_NOT_FOUND = 'app/resetPage404'
export const SET_LOADING = 'app/setLoading'
export const CLEAR_LOADING = 'app/clearLoading'

// Profile
export const ACTION_PROFILE = {
  SAVE: 'PROFILE/SAVE',
  LOAD: 'PROFILE/LOAD',
  LOGOUT: 'PROFILE/LOGOUT',
  LOGIN: 'PROFILE/LOGIN',
  REGISTER: 'PROFILE/REGISTER',
  UPDATE: 'PROFILE/UPDATE',
  LOGIN_FROM_FIREBASE: 'PROFILE/LOGIN_FROM_FIREBASE',
  CLEAR_PROFILE: 'PROFILE/CLEAR_PROFILE',
  FORGOT_PASSWORD: 'PROFILE/FORGOT_PASSWORD',
  RESET_PASSWORD: 'PROFILE/RESET_PASSWORD',
  CHECK_PASSWORD_TOKEN: 'PROFILE/CHECK_PASSWORD_TOKEN',
}

// User
export const ACTION_USER = {
  LOAD: 'USER/LOAD',
  SAVE: 'SAVE/LOGOUT',
  SET_ITEMS: 'USER/SET_ITEMS',
  SET_ITEM: 'USER/SET_ITEM',
  SET_NEW_ITEM: 'USER/SET_NEW_ITEM',
  SET_UPDATED_ITEM: 'USER/SET_UPDATED_ITEM',
  SET_DELETED_ITEM: 'USER/SET_DELETED_ITEM',

  LOAD_ITEMS: 'USER/LOAD_ITEMS',
  LOAD_ITEM: 'USER/LOAD_ITEM',
  ADD_NEW: 'USER/ADD_NEW',
  UPDATE: 'USER/UPDATE',
  DELETE: 'USER/DELETE',
}

// App
export const ACTION_APP = {
  SET_ITEMS: 'APP/SET_ITEMS',
  SET_ITEM: 'APP/SET_ITEM',
  SET_NEW_ITEM: 'APP/SET_NEW_ITEM',
  SET_UPDATED_ITEM: 'APP/SET_UPDATED_ITEM',
  SET_DELETED_ITEM: 'APP/SET_DELETED_ITEM',

  LOAD_ITEMS: 'APP/LOAD_ITEMS',
  LOAD_ITEM: 'APP/LOAD_ITEM',
  ADD_NEW: 'APP/ADD_NEW',
  UPDATE: 'APP/UPDATE',
  DELETE: 'APP/DELETE',

  TRIGGER_DEPLOY: 'APP/TRIGGER_DEPLOY',
  GET_DEPLOY_STATUS: 'APP/GET_DEPLOY_STATUS',
  EXPORT_CHANNELS: 'APP/EXPORT_CHANNELS',
  CHECK_ITEM_EXISTS: 'APP/CHECK_ITEM_EXISTS',
  OVERVIEW: 'AUDIENCE/OVERVIEW',
  OVERVIEW_DETAIL: 'AUDIENCE/OVERVIEW/DETAIL',
}

// App Users
export const ACTION_APP_USER = {
  SET_ITEMS: 'APP_USER/SET_ITEMS',
  LOAD_ITEMS: 'APP_USER/LOAD_ITEMS',
  LOAD_ITEM: 'APP_USER/LOAD_ITEM',
}

// Question
export const ACTION_QUESTION = {
  SET_ITEMS: 'QUESTION/SET_ITEMS',
  SET_ITEM: 'QUESTION/SET_ITEM',
  SET_NEW_ITEM: 'QUESTION/SET_NEW_ITEM',
  SET_UPDATED_ITEM: 'QUESTION/SET_UPDATED_ITEM',
  SET_DELETED_ITEM: 'QUESTION/SET_DELETED_ITEM',

  LOAD_ITEMS: 'QUESTION/LOAD_ITEMS',
  LOAD_ITEM: 'QUESTION/LOAD_ITEM',
  ADD_NEW: 'QUESTION/ADD_NEW',
  UPDATE: 'QUESTION/UPDATE',
  DELETE: 'QUESTION/DELETE',
  CLONE: 'QUESTION/CLONE',
}

// ACTION_GOAL
export const ACTION_GOAL = {
  SET_ITEMS: 'GOAL/SET_ITEMS',
  SET_ITEM: 'GOAL/SET_ITEM',
  SET_NEW_GOAL: 'GOAL/SET_NEW_GOAL',
  SET_UPDATED_GOAL: 'GOAL/SET_UPDATED_GOAL',
  SET_DELETED_GOAL: 'GOAL/SET_DELETED_GOAL',

  LOAD_ITEMS: 'GOAL/LOAD_ITEMS',
  LOAD_ITEM: 'GOAL/LOAD_ITEM',
  SAVE: 'GOAL/SAVE',
  ADD: 'GOAL/ADD',
  UPDATE: 'GOAL/UPDATE',
  DELETE: 'GOAL/DELETE',
  CLONE: 'GOAL/CLONE',
}

export const ACTION_FORM = {
  SET_ITEMS: 'FORM/SET_ITEMS',
  SET_ITEM: 'FORM/SET_ITEM',
  SET_NEW_ITEM: 'FORM/SET_NEW_ITEM',
  SET_UPDATED_ITEM: 'FORM/SET_UPDATED_ITEM',
  SET_DELETED_ITEM: 'FORM/SET_DELETED_ITEM',

  LOAD_ITEMS: 'FORM/LOAD_ITEMS',
  LOAD_ITEM: 'FORM/LOAD_ITEM',
  ADD_NEW: 'FORM/ADD_NEW',
  UPDATE: 'FORM/UPDATE',
  DELETE: 'FORM/DELETE',
  CLONE: 'FORM/CLONE',
}

// ACTION_SETTING
export const ACTION_SETTING = {
  SET_ITEMS: 'SETTING/SET_ITEMS',
  SET_ITEM: 'SETTING/SET_ITEM',
  SET_NEW_SETTING: 'SETTING/SET_NEW_SETTING',
  SET_UPDATED_ITEM: 'SETTING/SET_UPDATED_SETTING',
  SET_DELETED_ITEM: 'SETTING/SET_DELETED_SETTING',

  LOAD_ITEMS: 'SETTING/LOAD_ITEMS',
  LOAD_ITEM: 'SETTING/LOAD_ITEM',
  SAVE: 'SETTING/SAVE',
  ADD: 'SETTING/ADD',
  UPDATE: 'SETTING/UPDATE',
  DELETE: 'SETTING/DELETE',
}

// ACTION_INITIAL
export const ACTION_INITIAL = {
  SET_ITEMS: 'INITIAL/SET_ITEMS',
  SET_ITEM: 'INITIAL/SET_ITEM',
  SET_NEW_ITEM: 'INITIAL/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'INITIAL/SET_UPDATED_INITIAL',
  SET_DELETED_ITEM: 'INITIAL/SET_DELETED_INITIAL',

  LOAD_ITEMS_SUMMARY: 'INITIAL/LOAD_ITEMS_SUMMARY',
  // LOAD_ITEMS: 'INITIAL/LOAD_ITEMS',
  LOAD_ITEMS_WITH_DETAIL: 'INITIAL/LOAD_ITEMS_WITH_DETAIL',
  LOAD_ITEMS_WITHOUT_DETAIL: 'INITIAL/LOAD_ITEMS_WITHOUT_DETAIL',
  LOAD_ITEM: 'INITIAL/LOAD_ITEM',
  LOAD_STORY_ITEM: 'INITIAL/LOAD_STORY_ITEM',
  SAVE: 'INITIAL/SAVE',
  ADD: 'INITIAL/ADD',
  UPDATE: 'INITIAL/UPDATE',
  DELETE: 'INITIAL/DELETE',
  IS_ACTIVE: 'INITIAL/IS_ACTIVE',
  TEST_MODE: 'INITIAL/TEST_MODE',
  OVERVIEW_DETAIL: 'INITIAL/OVERVIEW/DETAIL',
}

// ACTION_PUSH
export const ACTION_PUSH = {
  SET_ITEMS: 'PUSH/SET_ITEMS',
  SET_ITEM: 'PUSH/SET_ITEM',
  SET_NEW_ITEM: 'PUSH/SET_NEW_PUSH',
  SET_UPDATED_ITEM: 'PUSH/SET_UPDATED_PUSH',
  SET_DELETED_ITEM: 'PUSH/SET_DELETED_PUSH',

  LOAD_ITEMS_SUMMARY: 'PUSH/LOAD_ITEMS_SUMMARY',
  LOAD_ITEMS_WITH_DETAIL: 'PUSH/LOAD_ITEMS_WITH_DETAIL',
  LOAD_ITEMS_WITHOUT_DETAIL: 'PUSH/LOAD_ITEMS_WITHOUT_DETAIL',
  LOAD_ITEM: 'PUSH/LOAD_ITEM',
  LOAD_STORY_ITEM: 'PUSH/LOAD_STORY_ITEM',
  LOAD_STORY_ITEMS: 'PUSH/LOAD_STORY_ITEMS',
  SAVE: 'PUSH/SAVE',
  ADD: 'PUSH/ADD',
  UPDATE: 'PUSH/UPDATE',
  DELETE: 'PUSH/DELETE',
  IS_ACTIVE: 'PUSH/IS_ACTIVE',
  TEST_MODE: 'PUSH/TEST_MODE',
  RUN_NOW: 'PUSH/RUN_NOW',
  OVERVIEW_DETAIL: 'PUSH/OVERVIEW/DETAIL',
  ERRORS_QUOTA: 'PUSH/ERRORS_QUOTA',
  WARNING_DELAY: 'PUSH/WARNING_DELAY',
}

// ACTION_MESSAGES
export const ACTION_MESSAGES = {
  SET_ITEMS: 'MESSAGES/SET_ITEMS',
  SET_ITEM: 'MESSAGES/SET_ITEM',
  SET_NEW_ITEM: 'MESSAGES/SET_NEW_MESSAGES',
  SET_UPDATED_ITEM: 'MESSAGES/SET_UPDATED_MESSAGES',
  SET_DELETED_ITEM: 'MESSAGES/SET_DELETED_MESSAGES',

  LOAD_ITEMS: 'MESSAGES/LOAD_ITEMS',
  LOAD_ITEM: 'MESSAGES/LOAD_ITEM',
  SAVE: 'MESSAGES/SAVE',
  ADD_NEW: 'MESSAGES/ADD_NEW',
  UPDATE: 'MESSAGES/UPDATE',
  DELETE: 'MESSAGES/DELETE',
  CLONE: 'MESSAGES/CLONE',
}

// ACTION_AUDIENCE
export const ACTION_AUDIENCE = {
  SET_ITEMS: 'AUDIENCE/SET_ITEMS',
  SET_ITEM: 'AUDIENCE/SET_ITEM',
  SET_NEW_ITEM: 'AUDIENCE/SET_NEW_AUDIENCE',
  SET_UPDATED_ITEM: 'AUDIENCE/SET_UPDATED_AUDIENCE',
  SET_DELETED_ITEM: 'AUDIENCE/SET_DELETED_AUDIENCE',
  SET_AUDIENCE_ANSWER: 'AUDIENCE/SET_AUDIENCE_ANSWER',
  SET_USER_ITEMS: 'AUDIENCE/SET_USER_ITEMS',

  LOAD_ITEMS: 'AUDIENCE/LOAD_ITEMS',
  LOAD_SIMPLE_ITEMS: 'AUDIENCE/LOAD_SIMPLE_ITEMS',
  LOAD_ITEM: 'AUDIENCE/LOAD_ITEM',
  LOAD_ITEM_ANSWER: 'AUDIENCE/LOAD_ITEM_ANSWER',
  SAVE: 'AUDIENCE/SAVE',
  ADD_NEW: 'AUDIENCE/ADD_NEW',
  UPDATE: 'AUDIENCE/UPDATE',
  DELETE: 'AUDIENCE/DELETE',
  LOAD_USER_ITEMS: 'AUDIENCE/LOAD_USER_ITEMS',
  LOAD_USER_ITEM_DETAILS: 'AUDIENCE/LOAD_USER_ITEM_DETAILS',
  IS_ACTIVE: 'AUDIENCE/IS_ACTIVE',
  TEST_MODE: 'AUDIENCE/TEST_MODE',
  FORK: 'AUDIENCE/FORK',
}

// Answer report
export const ACTION_ANSWER_REPORT = {
  LOAD_USER_DETAILS: 'ANSWER_REPORT/LOAD_USER_DETAILS',
}

// USER_ANSWER
export const USER_ANSWER = {
  SET_ITEMS: 'USER_ANSWER/SET_ITEMS',
  SET_ITEM: 'USER_ANSWER/SET_ITEM',
  SET_NEW_ITEM: 'USER_ANSWER/SET_NEW_ITEM',

  // LOAD_ITEMS: 'USER_ANSWER/LOAD_ITEMS',
  USER_ANSWER_ROWS: 'USER_ANSWER/USER_ANSWER_ROWS',
  LOAD_ITEM: 'USER_ANSWER/LOAD_ITEM',
  TOTAL_USERS_REPORT_SCENARIO: 'USER_ANSWER/TOTAL_USERS_REPORT_SCENARIO',
  GET_USER_ANSWER_BY_CAMPAIGN: 'USER_ANSWER/GET_USER_ANSWER_BY_CAMPAIGN',
}

// DELIVER_LOG
export const DELIVER_LOG = {
  SET_ITEMS: 'DELIVER_LOG/SET_ITEMS',
  SET_ITEM: 'DELIVER_LOG/SET_ITEM',
  SET_NEW_ITEM: 'DELIVER_LOG/SET_NEW_ITEM',

  LOAD_ITEMS: 'DELIVER_LOG/LOAD_ITEMS',
  LOAD_STORY_ITEMS: 'DELIVER_LOG/LOAD_STORY_ITEMS',
  LOAD_ITEM: 'DELIVER_LOG/LOAD_ITEM',
}

// VERSION_HISTORY
export const VERSION_HISTORY = {
  SET_ITEMS: 'VERSION_HISTORY/SET_ITEMS',
  LOAD_ITEMS: 'VERSION_HISTORY/LOAD_ITEMS',
  LOAD_ITEM_LATEST: 'VERSION_HISTORY/LOAD_ITEM_LATEST',

  SET_UPDATED_ITEM: 'VERSION_HISTORY/SET_UPDATED',
  UPDATE: 'VERSION_HISTORY/UPDATE',
}

// ACTION_RICH_MENU
export const ACTION_RICH_MENU = {
  SET_ITEMS: 'RICH_MENU/SET_ITEMS',
  SET_ITEM: 'RICH_MENU/SET_ITEM',
  SET_NEW_ITEM: 'RICH_MENU/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'RICH_MENU/SET_UPDATED',
  SET_DELETED_ITEM: 'RICH_MENU/SET_DELETED',

  LOAD_ITEMS: 'RICH_MENU/LOAD_ITEMS',
  LOAD_ITEM: 'RICH_MENU/LOAD_ITEM',
  SAVE: 'RICH_MENU/SAVE',
  ADD: 'RICH_MENU/ADD',
  UPDATE: 'RICH_MENU/UPDATE',
  DELETE: 'RICH_MENU/DELETE',
  IS_ACTIVE: 'RICH_MENU/IS_ACTIVE',
  TEST_MODE: 'RICH_MENU/TEST_MODE',
}

// ACTION_TARGET_SETTING
export const ACTION_TARGET_SETTING = {
  SET_ITEMS: 'TARGET_SETTING/SET_ITEMS',
  SET_ITEM: 'TARGET_SETTING/SET_ITEM',
  SET_NEW_ITEM: 'TARGET_SETTING/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'TARGET_SETTING/SET_UPDATED',
  SET_DELETED_ITEM: 'TARGET_SETTING/SET_DELETED',

  LOAD_ITEMS: 'TARGET_SETTING/LOAD_ITEMS',
  LOAD_ITEMS_WITHOUT_DETAIL: 'TARGET_SETTING/LOAD_ITEMS_WITHOUT_DETAIL',
  LOAD_ITEM: 'TARGET_SETTING/LOAD_ITEM',
  SAVE: 'TARGET_SETTING/SAVE',
  ADD: 'TARGET_SETTING/ADD',
  UPDATE: 'TARGET_SETTING/UPDATE',
  DELETE: 'TARGET_SETTING/DELETE',
  IS_ACTIVE: 'TARGET_SETTING/IS_ACTIVE',
  TEST_MODE: 'TARGET_SETTING/TEST_MODE',
}

// ACTION_CAMPAIGN
export const ACTION_CAMPAIGN = {
  SET_ITEMS: 'CAMPAIGN/SET_ITEMS',
  SET_ITEM: 'CAMPAIGN/SET_ITEM',
  SET_NEW_ITEM: 'CAMPAIGN/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'CAMPAIGN/SET_UPDATED',
  SET_DELETED_ITEM: 'CAMPAIGN/SET_DELETED',

  LOAD_ITEMS: 'CAMPAIGN/LOAD_ITEMS',
  LOAD_COMPACT_ITEMS: 'CAMPAIGN/LOAD_COMPACT_ITEMS',
  LOAD_ITEM: 'CAMPAIGN/LOAD_ITEM',
  LOAD_STORY_ITEM: 'CAMPAIGN/LOAD_STORY_ITEM',
  SAVE: 'CAMPAIGN/SAVE',
  ADD: 'CAMPAIGN/ADD',
  UPDATE: 'CAMPAIGN/UPDATE',
  DELETE: 'CAMPAIGN/DELETE',
  IS_ACTIVE: 'CAMPAIGN/IS_ACTIVE',
  TEST_MODE: 'CAMPAIGN/TEST_MODE',
  LOADED_TAG_JS: 'CAMPAIGN/LOADED_TAG_JS',
  SET_TAG_JS: 'CAMPAIGN/SET_TAG_JS',
  OVERVIEW_DETAIL: 'CAMPAIGN/OVERVIEW/DETAIL',
}

// ACTION_CAMPAIGN
export const ACTION_POPUP = {
  SET_ITEMS: 'POPUP/SET_ITEMS',
  SET_ITEM: 'POPUP/SET_ITEM',
  SET_NEW_ITEM: 'POPUP/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'POPUP/SET_UPDATED',
  SET_DELETED_ITEM: 'POPUP/SET_DELETED',

  LOAD_ITEMS: 'POPUP/LOAD_ITEMS',
  LOAD_ITEM: 'POPUP/LOAD_ITEM',
  SAVE: 'POPUP/SAVE',
  ADD: 'POPUP/ADD',
  UPDATE: 'POPUP/UPDATE',
  DELETE: 'POPUP/DELETE',
  IS_ACTIVE: 'POPUP/IS_ACTIVE',
  TEST_MODE: 'POPUP/TEST_MODE',
  OVERVIEW_DETAIL: 'POPUP/OVERVIEW/DETAIL',
}

// ACTION_TRAFFIC_SOURCE
export const ACTION_TRAFFIC_SOURCE = {
  SET_ITEMS: 'TRAFFIC_SOURCE/SET_ITEMS',
  SET_ITEM: 'TRAFFIC_SOURCE/SET_ITEM',
  SET_NEW_ITEM: 'TRAFFIC_SOURCE/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'TRAFFIC_SOURCE/SET_UPDATED',
  SET_DELETED_ITEM: 'TRAFFIC_SOURCE/SET_DELETED',

  LOAD_ITEMS: 'TRAFFIC_SOURCE/LOAD_ITEMS',
  LOAD_ITEM: 'TRAFFIC_SOURCE/LOAD_ITEM',
  SAVE: 'TRAFFIC_SOURCE/SAVE',
  ADD: 'TRAFFIC_SOURCE/ADD',
  UPDATE: 'TRAFFIC_SOURCE/UPDATE',
  DELETE: 'TRAFFIC_SOURCE/DELETE',
  IS_ACTIVE: 'TRAFFIC_SOURCE/IS_ACTIVE',
  TEST_MODE: 'TRAFFIC_SOURCE/TEST_MODE',
}

// ACTION_SCENARIO_TEMPLATE
export const ACTION_SCENARIO_TEMPLATE = {
  SET_ITEMS: 'SCENARIO_TEMPLATE/SET_ITEMS',
  SET_ITEM: 'SCENARIO_TEMPLATE/SET_ITEM',
  SET_NEW_ITEM: 'SCENARIO_TEMPLATE/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'SCENARIO_TEMPLATE/SET_UPDATED_INITIAL',
  SET_DELETED_ITEM: 'SCENARIO_TEMPLATE/SET_DELETED_INITIAL',

  LOAD_ITEMS_SUMMARY: 'SCENARIO_TEMPLATE/LOAD_ITEMS_SUMMARY',
  LOAD_ITEMS: 'SCENARIO_TEMPLATE/LOAD_ITEMS',
  LOAD_ITEMS_WITHOUT_DETAIL: 'SCENARIO_TEMPLATE/LOAD_ITEMS_WITHOUT_DETAIL',
  LOAD_ITEM: 'SCENARIO_TEMPLATE/LOAD_ITEM',
  SAVE: 'SCENARIO_TEMPLATE/SAVE',
  ADD: 'SCENARIO_TEMPLATE/ADD',
  UPDATE: 'SCENARIO_TEMPLATE/UPDATE',
  DELETE: 'SCENARIO_TEMPLATE/DELETE',
}

// Activity log
export const ACTION_ACTIVITY_LOG = {
  SET_ITEMS: 'ACTIVITY_LOG/SET_ITEMS',
  LOAD_ITEMS: 'ACTIVITY_LOG/LOAD_ITEMS',
  CONTENT_ARRAY_BUFFER: 'ACTIVITY_LOG/CONTENT_ARRAY_BUFFER',
  CONTENT_BLOD: 'ACTIVITY_LOG/CONTENT_BLOD',
}

// Media
export const ACTION_MEDIA = {
  SET_ITEMS: 'MEDIA/SET_ITEMS',
  SET_ITEM: 'MEDIA/SET_ITEM',
  SET_NEW_MEDIA: 'MEDIA/SET_NEW_MEDIA',
  SET_UPDATED_MEDIA: 'MEDIA/SET_UPDATED_MEDIA',
  SET_DELETED_MEDIA: 'MEDIA/SET_DELETED_MEDIA',

  LOAD_ITEMS: 'MEDIA/LOAD_ITEMS',
  LOAD_ITEM: 'MEDIA/LOAD_ITEM',
  SAVE: 'MEDIA/SAVE',
  ADD: 'MEDIA/ADD',
  UPDATE: 'MEDIA/UPDATE',
  DELETE: 'MEDIA/DELETE',
}

// Permission
export const ACTION_PERMISSION = {
  SET_ITEMS: 'PERMISSION/SET_ITEMS',
  LOAD_ITEMS: 'PERMISSION/LOAD_ITEMS',
  SYNC_PERMISSION_USERS: 'PERMISSION/SYNC_USERS',
}

// Auto response
export const ACTION_AUTO_RESPONSE = {
  SET_ITEMS: 'AUTO_RESPONSE/SET_ITEMS',
  SET_ITEM: 'AUTO_RESPONSE/SET_ITEM',
  SET_NEW_ITEM: 'AUTO_RESPONSE/SET_NEW_ITEM',
  SET_UPDATED_ITEM: 'AUTO_RESPONSE/SET_UPDATED_ITEM',
  SET_DELETED_ITEM: 'AUTO_RESPONSE/SET_DELETED_ITEM',

  LOAD_ITEMS: 'AUTO_RESPONSE/LOAD_ITEMS',
  LOAD_ITEM: 'AUTO_RESPONSE/LOAD_ITEM',
  ADD_NEW: 'AUTO_RESPONSE/ADD_NEW',
  UPDATE: 'AUTO_RESPONSE/UPDATE',
  DELETE: 'AUTO_RESPONSE/DELETE',
  IS_ACTIVE: 'AUTO_RESPONSE/IS_ACTIVE',
}

export const ACTION_STORY = {
  SET_ITEMS: 'STORY/SET_ITEMS',
  SET_ITEM: 'STORY/SET_ITEM',
  SET_NEW_ITEM: 'STORY/SET_NEW_INITIAL',
  SET_UPDATED_ITEM: 'STORY/SET_UPDATED',
  SET_DELETED_ITEM: 'STORY/SET_DELETED_ITEM',

  LOAD_ITEMS: 'STORY/LOAD_ITEMS',
  LOAD_ITEM: 'STORY/LOAD_ITEM',
  SAVE: 'STORY/SAVE',
  ADD: 'STORY/ADD',
  UPDATE: 'STORY/UPDATE',
  DELETE: 'STORY/DELETE',
  IS_ACTIVE: 'ACTION_STORY/IS_ACTIVE',
  TEST_MODE: 'ACTION_STORY/TEST_MODE',
  SENDING_TEST: 'ACTION_STORY/SENDING_TEST',
  REPORT: 'ACTION_STORY/REPORT',
  VALIDATE_DEFAULT_TYPE: 'ACTION_STORY/VALIDATE_DEFAULT_TYPE',
  OVERVIEW_DETAIL: 'STORY/OVERVIEW/DETAIL',
}
