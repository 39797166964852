<template>
  <AppSidebarTop :miniState="miniState" @drawerClick:collapsed="drawerClick" />
  <q-drawer
    ref="leftSidebarRef"
    v-model="drawer"
    show-if-above
    :mini="!drawer || miniState"
    :width="220"
    :breakpoint="500"
    class="left-sidebar-custom text-white text-weight-medium"
  >
    <q-scroll-area class="fit">
      <q-list>
        <template v-if="displayLeftSidebar">
          <q-item
            clickable
            :active="activePage === 'overview'"
            active-class="active-menu-link"
            class="hover-bg"
            v-ripple
            :to="detailPath('overview')"
            style="margin-top: 70px"
          >
            <q-item-section avatar>
              <q-icon name="grid_view" />
            </q-item-section>

            <q-item-section> {{ $t('menu.overview') }} </q-item-section>
          </q-item>
          <!--  default-opened -->
          <q-expansion-item
            expand-icon="keyboard_double_arrow_down"
            expanded-icon="keyboard_double_arrow_up"
            color="white"
            icon="send"
            :label="$t('menu.campaigns')"
            header-class="hover-bg"
          >
            <q-item
              clickable
              :active="activePage === 'stories' || activePage === 'story_setting'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('stories')"
            >
              <q-item-section class="menu-child">{{ $t('menu.stories') }}</q-item-section>
            </q-item>

            <q-item
              clickable
              v-ripple
              :active="activePage === 'initials' || activePage === 'initial_setting'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('initials')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.inital_message_campaigns') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="activePage === 'pushes' || activePage === 'push_setting'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('pushes')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.push_mespage_campaigns') }} </q-item-section>
            </q-item>

            <q-item
              clickable
              :active="activePage === 'response_setting'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('response_setting')"
            >
              <q-item-section class="menu-child">{{ $t('menu.response_setting') }}</q-item-section>
            </q-item>
            <q-item
              clickable
              :active="activePage === 'campaign_traffic_sources'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('campaign_traffic_sources')"
            >
              <q-item-section class="menu-child">{{ $t('menu.campaign_traffic_sources') }}</q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-icon="keyboard_double_arrow_down"
            expanded-icon="keyboard_double_arrow_up"
            icon="list_alt"
            :label="$t('menu.assets')"
            header-class="hover-bg"
          >
            <q-item
              clickable
              :active="activePage === 'campaigns'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('campaigns')"
            >
              <q-item-section class="menu-child">{{ $t('menu.user_acquisitions') }}</q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="activePage === 'assets'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('assets')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.scenario_content') }}</q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="activePage === 'scenario_templates' || activePage === 'scenario_template_setting'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('scenario_templates')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.scenario_templates') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              :active="activePage === 'media'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('media_manager')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.media_manager') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              :active="activePage === 'rich_menus'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('rich_menus')"
            >
              <q-item-section class="menu-child">{{ $t('menu.rich_menu_builder') }}</q-item-section>
            </q-item>
            <q-item
              clickable
              :active="activePage === 'target_settings'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('target_settings')"
            >
              <q-item-section class="menu-child">{{ $t('menu.target_setting') }}</q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-icon="keyboard_double_arrow_down"
            expanded-icon="keyboard_double_arrow_up"
            icon="groups"
            :label="$t('label.user')"
            header-class="hover-bg"
          >
            <q-item
              clickable
              :active="activePage === 'app_line_users'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('app_line_users')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.app_line_users') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              :active="activePage === 'builder'"
              active-class="active-menu-link"
              class="hover-bg"
              v-ripple
              :to="detailPath('builder')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.audience_builder') }} </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-icon="keyboard_double_arrow_down"
            expanded-icon="keyboard_double_arrow_up"
            icon="show_chart"
            :label="$t('report')"
            header-class="hover-bg"
          >
            <q-item
              clickable
              v-ripple
              :active="activePage === 'scenario_report'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('scenario_report')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.scenario_report') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="activePage === 'q_a_report'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('q_a_report')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.q_a_report') }} </q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="activePage === 'form_report'"
              active-class="active-menu-link"
              class="hover-bg"
              :to="detailPath('form_report')"
            >
              <q-item-section class="menu-child"> {{ $t('menu.form_report') }} </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-item
            clickable
            :active="activePage === 'app_information'"
            active-class="active-menu-link"
            class="hover-bg"
            v-ripple
            :to="detailPath('app_information')"
          >
            <q-item-section avatar>
              <q-icon name="settings" />
            </q-item-section>

            <q-item-section> {{ $t('menu.app_information') }} </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-scroll-area>
    <AppLogo :miniState="miniState" />
  </q-drawer>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator'
import { Vue, Options } from 'vue-class-component'
import { ACTION_PROFILE } from '@/store/actions'
import { localStore } from '@/utils/localstore'
import { debounce } from 'quasar'
import { UserModel } from '@/models/user-model'

import UserAvatar from '@/components/user/UserAvatar.vue'
import AppSidebarTop from '@/components/layout/AppSidebarTop.vue'
import AppLogo from '../common/AppLogo.vue'

@Options({
  components: { UserAvatar, AppSidebarTop, AppLogo },
  emits: ['update:collapsed'],
})
export default class AppSidebarLeft extends Vue {
  selected = ''
  miniState = false
  drawer = false

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  get displayLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  get appId() {
    return this.$route.params.app_id
  }

  get activePage() {
    return this.$route.name || 'overview'
  }

  @Watch('miniState')
  onCollapseChanged() {
    // Remove scroll left on mini state. Quasar has a strange thing here
    debounce(() => {
      if (this.$refs.leftSidebarRef) {
        this.$refs.leftSidebarRef.$el.classList.remove('q-drawer--mini-animate')
      }
    }, 150).call(this)
  }

  drawerClick() {
    this.miniState = !this.miniState
  }

  gotoDetail(page) {
    this.goto(page, { app_id: this.appId, page })
  }

  detailPath(page) {
    return {
      name: page,
      params: { app_id: this.appId },
    }
  }

  gotoUserProfile() {
    this.goto('user_profile_view', { id: this.userInfo._id })
  }

  gotoEditProfile() {
    this.goto('user_profile_edit')
  }

  gotoHome() {
    this.goto('home')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.goto('login')
    } catch (error) {
      console.log(error)
    }
  }

  created() {
    this.miniState = !!localStore.getItem(this.collapseKey)
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
:deep(.q-item__label--caption) {
  color: #999;
}
:deep(.active-menu-link) {
  background: #fff;
  color: #7491bd !important;
}
:deep(.q-drawer:not(.q-drawer--mini)) {
  .q-item {
    font-weight: bold;
  }
}
.separator-logo {
  background: none !important;
}

:deep(.q-separator) {
  background: #0e1016;
}

:deep(.left-sidebar-custom) {
  background: #7491bd !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

:deep(.q-drawer) {
  position: fixed;
  top: 0;
}
:deep(.hover-bg:hover) {
  background-color: #5d7598;
  color: white; /* Optional: change text color on hover */
}
:deep(.hover-bg .q-item__section--side) {
  color: white !important;
}
</style>
