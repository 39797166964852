import { ACTION_MESSAGES } from './actions'
import { IMessage } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import messageApi from '@/api/message'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IMessage[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    messages: (state: TState) => {
      return state.items as IMessage[]
    },
  },
  mutations: {
    // [List]
    [ACTION_MESSAGES.SET_ITEMS]: (state: TState, { items }: { items: IMessage[] }) => {
      state.items = cloneDeep(items) as IMessage[]
    },

    // [New item]
    [ACTION_MESSAGES.SET_NEW_ITEM]: (state: TState, { item }: { item: IMessage }) => {
      state.items = apiListChangedHandler<IMessage>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_MESSAGES.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IMessage }) => {
      state.items = apiListChangedHandler<IMessage>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_MESSAGES.SET_DELETED_ITEM]: (state: TState, { item }: { item: IMessage }) => {
      state.items = apiListChangedHandler<IMessage>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_MESSAGES.LOAD_ITEMS]: async ({ commit }, params: IMessage) => {
      const key = constant.LOCAL_KEYS.ASSETS_MESSAGES + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await messageApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_MESSAGES.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_MESSAGES.LOAD_ITEM]: async ({ commit }, params: IMessage) => {
      const resp = await messageApi.single(params)
      return resp
    },

    [ACTION_MESSAGES.ADD_NEW]: async ({ commit }, payload: IMessage) => {
      const resp = await messageApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_MESSAGES + resp.app_id)
        commit(ACTION_MESSAGES.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_MESSAGES.UPDATE]: async ({ commit }, payload: IMessage) => {
      const resp = await messageApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_MESSAGES + resp.app_id)
        commit(ACTION_MESSAGES.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_MESSAGES.DELETE]: async ({ commit }, id: string) => {
      const resp = await messageApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_MESSAGES + resp.app_id)
        commit(ACTION_MESSAGES.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_MESSAGES.CLONE]: async ({ commit }, id: string) => {
      const resp = await messageApi.clone(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_MESSAGES + resp.app_id)
        commit(ACTION_MESSAGES.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
  },
}
